import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import { Timeline } from "@/components/timeline";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { BsTriangleFill } from "react-icons/bs";

const Mothrly = () => {
  return (
    <>
      <GatsbySeo
        title="Mothrly - Services | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/services/mothrly/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/services/mothrly/",
          title: "Mothrly - Services | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Framing a digital shelter during &amp; post pregnancy.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <p className="text-base text-black font-poppins font-thin leading-8 mt-2">
              Mothrly is an interactive platform for both expected mothers and
              doulas (a trained professional to support expectant
              parents)/gynecologists where pregnant ladies can easily book a
              meeting with professionals. Mothrly is a platform to make the
              lives of new mothers easier by providing them care facilities from
              booking doula services to gynecologists at their fingertips. For
              doctors, too, it's a great platform as they can easily schedule
              meetings.
            </p>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/asknaso354sabi.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="grid grid-cols-1 gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Problem Statement
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Expecting women are concerned about their pregnancy and our
                    confused about how they can hire a doula who will help them
                    till the delivery and after their baby is born. They are
                    hesitant to hire someone at this crucial stage and they want
                    a systematic platform.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Objective
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    To make the life of new mothers easier by providing them
                    with care facilities ranging from doula services to
                    gynecologists at their fingertips with an easy-to-use
                    interface.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Possible Solution
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Creating a user's friendly profile where pregnant ladies can
                    set up a profile, book a doula service, and consult a
                    gynecologist. In addition, a platform for doctors where they
                    can set up their profile, find the bookings requests, and
                    check their earnings. All in all, engaging platform with
                    multiple features for soon-to-be a mother.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-black relative overflow-hidden mt-20 lg:mt-0">
          <div className="max-w-7xl mx-auto px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center px-4 lg:px-0 lg:pr-12 sm:mx-0 lg:col-span-6">
                <div className="relative z-10 gap-x-4 lg:px-0 my-8 lg:my-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <div className="text-white">
                    <h2 className="text-lg font-medium font-poppins text-white capitalize">
                      Objective:
                    </h2>
                    <p className="text-base font-poppins font-thin text-white">
                      The objective is to make the lives of new mothers/ parents
                      easier by providing them with care facilities ranging from
                      doula services to gynecologists at their fingertips.
                    </p>
                  </div>
                  <div className="text-white mt-4">
                    <h1 className="text-lg font-poppins font-medium text-white captalize">
                      Tools &amp; Technologies
                    </h1>
                    <p className="text-base font-poppins font-thin">
                      Figma, Flutter
                    </p>
                  </div>
                  <div className="text-white mt-4">
                    <h1 className="text-lg font-poppins font-medium text-white captalize">
                      Product Features:
                    </h1>
                    <p className="text-base font-poppins font-thin mt-2 captalize">
                      For Parents:
                    </p>
                    <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc">
                      <li>Setting up a profile</li>
                      <li>Book a Doula Service</li>
                      <li>Consult a gynecologist</li>
                      <li>Build and participate in the community</li>
                      <li>
                        Engaging features - Baby Face Generator and Create an
                        Invite
                      </li>
                    </ul>
                    <p className="text-base font-poppins font-thin mt-2 captalize">
                      For Doulas &amp; Doctors:
                    </p>
                    <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc">
                      <li>Setting up a profile</li>
                      <li>Booking Requests</li>
                      <li>Check Your Earnings</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-6">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <StaticImage
                    src="../../images/93907786_F98nsw39doHiTPDwsQGF2TSFia8TQijW1.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={700}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Research Process
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Domain Research",
                  "Customer interviews",
                  "Competitive Analysis",
                  "Customer Journey",
                  "Survey",
                ]}
              />
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Discovery Phase
            </h2>
            <h6 className="text-black text-left text-base font-poppins font-thin captalize">
              Finding the right audience
            </h6>
            <div className="mt-6">
              <p className="text-base text-black font-poppins font-thin leading-8 mt-2">
                Finding the right audience The first stage of our process is the
                find the right target user group with research and analysis. As
                when you find the right audience you can provide the best
                solutions.
              </p>
              <ul className="text-base font-poppins font-thin list-disc ml-6 leading-8">
                <li className="mt-2">Targeted Audience</li>
                <li className="mt-2">Pregnant women</li>
                <li className="mt-2">Doulas</li>
                <li className="mt-2">Gynecologist</li>
                <li className="mt-2">New parents</li>
                <li className="mt-2">Elderly</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-[#F0F0F0] mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8 py-10 lg:py-12">
            <div className="w-full flex items-center">
              <h2 className=" w-full text-black text-left text-2xl font-poppins font-semibold flex justify-start captalize tracking-tight">
                Customers Persona
              </h2>
              <div className="justify-end w-full flex text-gray-400">
                <div className="swiper-prev cursor-pointer">
                  <BsTriangleFill size={"1.5rem"} className="-rotate-90" />
                </div>
                <div className="swiper-next ml-8 cursor-pointer">
                  <BsTriangleFill size={"1.5rem"} className="rotate-90" />
                </div>
              </div>
            </div>
            <div className="mt-6 lg:mt-12">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                navigation={{
                  prevEl: ".swiper-prev",
                  nextEl: ".swiper-next",
                }}
                modules={[Navigation]}
                breakpoints={{
                  360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 items-center">
                    <div className="w-full">
                      <StaticImage
                        src="../../images/sdfsijps324l.png"
                        alt=""
                        layout="constrained"
                        placeholder="blurred"
                        quality={95}
                        height={800}
                        className="w-full"
                        formats={["auto", "png"]}
                      />
                      <h5 className="text-base text-black text-center font-poppins font-thin leading-8 mt-2">
                        Reema (Pregnant Woman/soon-to-be mother)
                      </h5>
                    </div>
                    <div className="w-full">
                      <p className="text-base text-black font-poppins font-thin leading-8 mt-2">
                        Reema is a soon-to-be mom. She is very overwhelmed with
                        her pregnancy and people have suggested she hire a doula
                        who will help her till the delivery and after her baby
                        is born. She is hesitant to hire someone at this crucial
                        stage.
                      </p>
                      <p className="text-base font-poppins font-thin mt-2 captalize">
                        Expectations
                      </p>
                      <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                        <li>Easy journey to find the doula</li>
                        <li>Systematic approach</li>
                        <li>Verified registered doulas</li>
                        <li>Genuine information and reviews</li>
                        <li>
                          Interactive Community for parents and soon-to-be
                          parents
                        </li>
                      </ul>
                      <p className="text-base font-poppins font-thin mt-2 captalize leading-8">
                        Pain Points
                      </p>
                      <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                        <li>Thinks it will be time consuming</li>
                        <li>Worry about misleading Information</li>
                        <li>Worry about verification of Doula</li>
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 items-center">
                    <div className="w-full">
                      <StaticImage
                        src="../../images/opjwwrsdy7qwqw.png"
                        alt=""
                        layout="constrained"
                        placeholder="blurred"
                        quality={95}
                        height={800}
                        className="w-full"
                        formats={["auto", "png"]}
                      />
                      <h5 className="text-base text-black text-center font-poppins font-thin leading-8 mt-2">
                        Reema (Pregnant Woman/soon-to-be mother)
                      </h5>
                    </div>
                    <div className="w-full">
                      <p className="text-base text-black font-poppins font-thin leading-8 mt-2">
                        Nisha is a new mom. She is worried about her newly born
                        child as there is no one to guide her at this stage.
                        Also, she doesn’t want her child to get a check-up with
                        a new doctor; so wants someone experienced.
                      </p>
                      <p className="text-base font-poppins font-thin mt-2 captalize leading-8">
                        Expectations
                      </p>
                      <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                        <li>An experienced doula for her child</li>
                        <li>An end-to-end platform</li>
                        <li>Regular guidance</li>
                      </ul>
                      <p className="text-base font-poppins font-thin mt-2 captalize leading-8">
                        Pain Points
                      </p>
                      <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                        <li>Don’t want an unexperienced doula</li>
                        <li>Worry about misleading Information</li>
                        <li>Stressed about her new baby</li>
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 items-center">
                    <div className="w-full">
                      <StaticImage
                        src="../../images/nmdfsih56asnsa.png"
                        alt=""
                        layout="constrained"
                        placeholder="blurred"
                        quality={95}
                        height={800}
                        className="w-full"
                        formats={["auto", "png"]}
                      />
                      <h5 className="text-base text-black text-center font-poppins font-thin leading-8 mt-2">
                        Ravi (A new father)
                      </h5>
                    </div>
                    <div className="w-full">
                      <p className="text-base text-black font-poppins font-thin leading-8 mt-2">
                        Ravi is a government officer and a new father, who
                        cannot give time to his wife due to his busy schedule.
                        He is looking for experienced doulas for the support of
                        his wife. But, he has a busy schedule so he cannot get
                        an appointment. Thus, he wants instant support and
                        booking.
                      </p>
                      <p className="text-base font-poppins font-thin mt-2 captalize leading-8">
                        Expectations
                      </p>
                      <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                        <li>An experienced doula for her child</li>
                        <li>A quick booking platform</li>
                        <li>Better support from doulas </li>
                      </ul>
                      <p className="text-base font-poppins font-thin mt-2 captalize leading-8">
                        Pain Points
                      </p>
                      <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                        <li>Don’t want an unexperienced doula</li>
                        <li>
                          Stressed about his busy schedule &amp; inefficient
                          booking platforms
                        </li>
                        <li>Worried about his wife’s health</li>
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Customers’ Journey
            </h2>
            <h6 className="text-black text-left text-base font-poppins font-thin captalize">
              Users journey map
            </h6>
            <div className="mt-6">
              <p className="text-base text-black font-poppins font-thin leading-8 my-4">
                Based on users' research, we create a customer journey map for a
                better understanding of pain points and expectations in the
                overall journey that users may encounter while booking a doula.
              </p>
              <StaticImage
                src="../../images/asklnlaso2362jb.png"
                alt=""
                layout="constrained"
                placeholder="blurred"
                quality={95}
                className="w-full h-60 md:h-92 lg:h-auto"
                formats={["auto", "png"]}
              />
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Design Phase
            </h2>
            <div className="mt-6">
              <p className="text-base font-poppins font-thin mt-2 captalize">
                Problems
              </p>
              <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                <li>Fewer choices</li>
                <li>Search is intensive</li>
                <li>Lack of instant support</li>
              </ul>
              <p className="text-base font-poppins font-thin mt-2 captalize">
                Solutions
              </p>
              <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                <li>Providing better search results</li>
                <li>Enabling more control over the process</li>
                <li>Helping moms faster</li>
                <li>Bridging the communication gap</li>
              </ul>
              <p className="text-base text-black font-poppins font-thin leading-normal mt-2 leading-8">
                We are building an application where moms can easily get support
                and care at their fingertips. For doulas and gynecologists, they
                can easily schedule their meetings with the help of build in
                calendars.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Product Features
            </h2>
            <div className="mt-6">
              <p className="text-base font-poppins font-thin mt-2 captalize">
                For Parents:
              </p>
              <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                <li>Setting up a profile</li>
                <li>Book a Doula Service</li>
                <li>Consult a gynecologist</li>
                <li>Build and participate in the community</li>
                <li>
                  Engaging features - Baby Face Generator and Create an Invite
                </li>
              </ul>
              <p className="text-base font-poppins font-thin mt-2 captalize">
                For Doulas &amp; Doctors:
              </p>
              <ul className="text-base font-poppins font-thin mt-2 ml-10 list-disc leading-8">
                <li>Setting up a profile</li>
                <li>Booking Requests</li>
                <li>Check Your Earnings</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize tracking-tight">
              Glipmse Of Our Work
            </h2>
            <div className="mt-6 lg:mt-12">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide>
                  <StaticImage
                    src="../../images/sasiadfywvhkksf.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={850}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src="../../images/mnzxsad7asdhk.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={850}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src="../../images/uihwbkbad5sd.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={850}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        {/* <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="flex flex-wrap ">
              <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
                Glipmse Of Our Work
              </h2>
              <div className="mt-8">
                <StaticImage
                  src="../../images/sasiadfywvhkksf.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={850}
                  className="w-full"
                  formats={["auto", "png"]}
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Usability Testing
            </h2>
            <div className="mt-6">
              <p className="text-base text-black font-poppins font-thin leading-normal mt-2">
                Usability test(scored 8/10)
              </p>
              <ul className="text-base font-poppins font-thin list-disc leading-8 ml-6">
                <li className="mt-2">
                  The Goal - To help women in getting instant support and
                  bookings from doulas &amp; gynecologists.
                </li>
                <li className="mt-2">
                  The Task - Creating a seamless and user-friendly platform
                </li>
                <li className="mt-2">
                  Success - The task is completed in less than 5 error clicks.
                  So the model is clear.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Conclusion
            </h2>
            <div className="mt-6">
              <p className="text-base text-black font-poppins font-thin leading-normal mt-2 leading-8">
                Our team effectively finished the project in 3 weeks after
                understanding the client's presentation. We create a seamless
                and users friendly experience by understanding the need of your
                business. Do you want to try our UI/UX services? Let's connect!
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Mothrly;
